import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import logo from '../../logo.png';


export default function PatientReport(props) {

    return (
      <div >
        <Grid container>
            <Grid item align="Center" xs={12} md={12}> 
              <Box pt={3}  align="center">
                  <img src={logo} alt="Logo" width="70" height="auto"/>
              </Box>
              <h4 style={{lineHeight:"2"}}>МИНИСТЕРСТВО ЗДРАВООХРАНЕНИЯ РЕСПУБЛИКИ БЕЛАРУСЬ</h4>     
              <h4 style={{lineHeight:"0"}}>ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ</h4> 
              <h4 style={{lineHeight:"0"}}>"РЕСПУБЛИКАНСКИЙ НАУЧНО-ПРАКТИЧЕСКИЙ ЦЕНТР</h4> 
              <h4 style={{lineHeight:"0"}}>ОНКОЛОГИИ И МЕДИЦИНСКОЙ РАДИОЛОГИИ</h4>
              <h4 style={{lineHeight:"0"}}>им. Н.Н. Александрова"</h4>
              <h4 style={{lineHeight:"0"}}>(РНПЦ ОМР им. Н.Н. Александрова)</h4>
            </Grid>
            <Grid item align="Center" xs={12} md={12}> 
              <h4 style={{lineHeight:"0"}}>Сведения о рандомизации по научному протоколу № {props.protocolNumber}</h4>
            </Grid>
            <Grid item align="Center" xs={1} md={1}> 
              
            </Grid>
            <Grid style={{lineHeight:"0"}} item align="left" xs={5} md={5}> 
              <b><p style={{lineHeight:"0.5"}}>Пациент</p> 
              <p style={{lineHeight:"0.5"}}>Номер амбулаторной карты</p>
              <p style={{lineHeight:"0.5"}}>Номер истории болезни</p>
              <p style={{lineHeight:"0.5"}}>Дата рождения</p>
              <p style={{lineHeight:"0.5"}}>Дата рандомизации</p>
              <p style={{lineHeight:"0.5"}}>ФИО лечащего врача</p>
              <p style={{lineHeight:"0.5"}}>ФИО проводившего рандомизацию</p>
              <p style={{lineHeight:"0.5"}}>Диагноз:</p></b>
            </Grid>
            <Grid style={{lineHeight:"0"}} item align="left" xs={6} md={6}> 
              <p style={{lineHeight:"0.5"}}>- {props.fullName}</p>
              <p style={{lineHeight:"0.5"}}>- {props.outpatientCardNumber}</p>  
              <p style={{lineHeight:"0.5"}}>- {props.clinicalHistoryNumber}</p>
              <p style={{lineHeight:"0.5"}}>- {props.dateOfBirth}</p>
              <p style={{lineHeight:"0.5"}}>- {props.dateOfRandomization}</p>
              <p style={{lineHeight:"0.5"}}>- {props.treatingDoctor}</p>
              <p style={{lineHeight:"0.5"}}>- {props.randomizer}</p>
            </Grid>
            <Grid item align="left" xs={1} md={1}> 
            </Grid>
            <Grid item align="left" xs={10} md={10}> 
            <p>{props.diagnosis}</p>
            </Grid>
            <Grid item align="Center" xs={1} md={1}> 
            </Grid>
            <Grid  item align="left" xs={1} md={1}> 
            </Grid>
            <Grid  item align="left" xs={11} md={11}> 
            <b><p>Направлен в группу: {props.targetGroup}</p></b>
            </Grid>
        </Grid>
      </div>
    );
  }