import React, { Component} from "react";
import jwt_decode from "jwt-decode";
import axiosInstance from "./API/axiosAPI";

import LoginForm from './components/forms/LoginForm'
import Navbar from "./components/Navbar"
import Menu from './components/Menu'


class App extends Component {
    constructor() {
      super();
      this.state = {
        username : '',
        password : '',
        fullUsername: '',
        userData: [],
        userId: ''
        }
        this.handleLogout = this.handleLogout.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getPatientsData = this.getPatientsData.bind(this)
        this.getFullUsername = this.getFullUsername.bind(this)
    }
        
    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    async handleSubmit(event) {
        event.preventDefault();
        console.log('handle_submit_start')
        try {
            const response = await axiosInstance.post('/token/obtain/', {
                username: this.state.username,
                password: this.state.password
            });
            console.log(response.data)
            axiosInstance.defaults.headers['Authorization'] = "JWT " + response.data.access;
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            window.location.reload(false);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async handleLogout() {
        try {
          const response = await axiosInstance.post('/blacklist/', {
              "refresh_token": localStorage.getItem("refresh_token")
          });
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          axiosInstance.defaults.headers['Authorization'] = null;
          window.location.reload(false);
          return response;
        }
        catch (e) {
          console.log(e);
        }
    };

    async getFullUsername(){
      try {
          let response = await axiosInstance.get('/get_full_username/', {
              params: {
                user_id: jwt_decode(localStorage.getItem('access_token')).user_id
              }
            });
          const responseData = response.data.full_username;
          this.setState({fullUsername: responseData});
          return responseData;
      }catch(error){
          console.log("Error: ", JSON.stringify(error, null, 4));
          throw error;
      }
  }

    async getPatientsData(){
        try {
            let response = await axiosInstance.get('/get_patients_data/', {
                params: {
                  user_id: jwt_decode(localStorage.getItem('access_token')).user_id
                }
              });
            const userData = response.data.user_data;
            this.setState({
                userData: userData,
                userId: jwt_decode(localStorage.getItem('access_token')).user_id
            });
            return userData;
        }catch(error){
            console.log("Error: ", JSON.stringify(error, null, 4));
            throw error;
        }
    }

    componentDidMount() {
        if (localStorage.getItem('access_token')) {
            this.getFullUsername();
            this.getPatientsData(); 
        }
    }

    
    render() {
      return (
          <>
            {localStorage.getItem('access_token') 
              ? <>
                <Navbar handleLogout={this.handleLogout} 
                        full_username={this.state.fullUsername}/>
                <Menu data={this.state.userData} 
                      fullUsername={this.state.fullUsername}
                      userId={this.state.userId}
                      getPatientsData={this.getPatientsData}/>
                </>
              : <LoginForm handleSubmit={this.handleSubmit}
                           username={this.state.username}
                           password={this.state.password}
                           handleChange={this.handleChange} />
            }
          </>
      );
  }
}

export default App;

