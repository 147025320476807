import React, { Component} from "react";

import axiosInstance from '../API/axiosAPI';
import RandomizeForm from './forms/RandomizeForm'
import PatientReportDialog from './reportDialogs/PatientReportDialog';
import Snackbars from './misc/Snackbars'


class Randomize extends Component {
  constructor(props) {
    super(props);
    this.state = {
        protocolId: '',
        outpatientCardNumber: '',
        clinicalHistoryNumber: '',
        firstName: '',
        secondName: '',
        thirdName: '',
        dateOfBirth: '',
        diagnosis: '',
        randomizeResponsePatientData: '',
        vertical: '',
        horizontal: '',
        open: false,
        scroll: 'paper',
        showSuccessfullyRandomizedSnackbar: false,
        showErrorsDuringRandomizationSnackbar: false,
      }
    this.handleChange = this.handleChange.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
    this.handleSubmitRandomizeButton = this.handleSubmitRandomizeButton.bind(this);
  }

  handleOpen() {
    this.setState({open: true, scroll: 'paper'});
  }

  handleClose() {
    this.setState({open: false});
  }

  handleCloseSnackbar() {
    this.setState({showSuccessfullyRandomizedSnackbar: false,
                   showErrorsDuringRandomizationSnackbar: false});
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
}

  async handleSubmitRandomizeButton(event) {
    event.preventDefault();
    try {
        const response = await axiosInstance.post('/randomize/', {
          protocolId: this.state.protocolId,
          secondName: this.state.secondName,
          firstName: this.state.firstName,
          thirdName: this.state.thirdName,
          dateOfBirth: this.state.dateOfBirth,
          outpatientCardNumber: this.state.outpatientCardNumber,
          clinicalHistoryNumber: this.state.clinicalHistoryNumber,
          treatingDoctor: this.state.treatingDoctor,
          diagnosis: this.state.diagnosis,
          randomizerId: this.props.userId
        });
        if (!response.data.error_message) {
          this.setState({
            randomizeResponsePatientData: response.data,
            open: true,
            showSuccessfullyRandomizedSnackbar: true,
            outpatientCardNumber: '',
            clinicalHistoryNumber: '',
            firstName: '',
            secondName: '',
            thirdName: '',
            dateOfBirth: '',
            treatingDoctor: '',
            diagnosis: '',
          })
          this.props.getPatientsData();
        }
        else {
          this.setState({showErrorsDuringRandomizationSnackbar: true });
        }
       return response;
    } catch (error) {
       throw error;
    }
  };

  render() {
    return (
    <> 
      {console.log(this.props.data)}
      <PatientReportDialog patientData={this.state.randomizeResponsePatientData}
                           open={this.state.open}
                           handleOpen={this.handleOpen}
                           handleClose={this.handleClose}
                           scroll={this.state.scroll}/>    
      <Snackbars showSuccessfullyRandomizedSnackbar={this.state.showSuccessfullyRandomizedSnackbar}
                 showErrorsDuringRandomizationSnackbar={this.state.showErrorsDuringRandomizationSnackbar}
                 handleCloseSnackbar={this.handleCloseSnackbar} />   
      <RandomizeForm data={this.props.data}
                     handleSubmitRandomizeButton={this.handleSubmitRandomizeButton}
                     handleChange={this.handleChange}
                     protocolId={this.state.protocolId}
                     secondName={this.state.secondName}
                     firstName={this.state.firstName}
                     thirdName={this.state.thirdName}
                     dateOfBirth={this.state.dateOfBirth}д
                     outpatientCardNumber={this.state.outpatientCardNumber}
                     clinicalHistoryNumber={this.state.clinicalHistoryNumber}
                     treatingDoctor={this.state.treatingDoctor}
                     diagnosis={this.state.diagnosis}
                     />
      
    </>
    );
}
}

export default Randomize;