import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import logo from '../../logo.png';
import ProtocolReportData from './ProtocolReportData'


export default function ProtocolReport(props) {

  return (
      <div >
        <Grid container>
            <Grid item align="Center" xs={12} md={12}> 
              <Box pt={3}  align="center">
                  <img src={logo} alt="Logo" width="70" height="auto"/>
              </Box>
              <h4 style={{lineHeight:"2"}}>МИНИСТЕРСТВО ЗДРАВООХРАНЕНИЯ РЕСПУБЛИКИ БЕЛАРУСЬ</h4>     
              <h4 style={{lineHeight:"0"}}>ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ</h4> 
              <h4 style={{lineHeight:"0"}}>"РЕСПУБЛИКАНСКИЙ НАУЧНО-ПРАКТИЧЕСКИЙ ЦЕНТР</h4> 
              <h4 style={{lineHeight:"0"}}>ОНКОЛОГИИ И МЕДИЦИНСКОЙ РАДИОЛОГИИ</h4>
              <h4 style={{lineHeight:"0"}}>им. Н.Н. Александрова"</h4>
              <h4 style={{lineHeight:"0"}}>(РНПЦ ОМР им. Н.Н. Александрова)</h4>
            </Grid>
            <Grid item align="Center" xs={12} md={12}> 
              <h4 style={{lineHeight:"0"}}>Сведения о рандомизации по научному протоколу № {props.protocolData.protocol_number}</h4>
              <br />
            </Grid>
            {(props.isShown)
            ? <Grid item align="Left" xs={12} md={12}> 
              <hr/>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>Протокол: <b>{props.protocolName}</b></small>
              </Grid>
            : null
            }
            
            <Grid item align="Center" xs={1} md={1}> 
              
            </Grid>
            <ProtocolReportData isShown={props.isShown} protocolData={props.protocolData}/>
        </Grid>
      </div>
    );
  }