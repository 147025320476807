import React from "react";
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


export default function RandomizeForm(props) {
    return (
        <Card>
        <CardContent>
            <form onSubmit={(event) => props.handleSubmitRandomizeButton(event)}>
            <Grid container spacing={3}>
              <Grid item align="left" xs={12} md={12}>
                <InputLabel  shrink id="protocol-label">
                  Протокол:
                </InputLabel>
                  <Select
                    required
                    labelId="protocol-label"
                    id="protocolId"
                    name="protocolId"
                    fullWidth
                    value={props.protocolId}
                    onChange={props.handleChange}
                  >
                      {console.log(props.data)}
                      { (props.data.protocols)
                        ? props.data.protocols.map(protocol => (<MenuItem value={protocol.id}>{protocol.name}</MenuItem>))
                        : null
                      }
                  </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField 
                  value={props.secondName}
                  required
                  name="secondName"
                  id="secondName" 
                  label="Фамилия" 
                  onChange={props.handleChange}
                  fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  value={props.firstName}
                  name="firstName"
                  id="firstName"
                  label="Имя"
                  fullWidth
                  onChange={props.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} >
                <TextField 
                  required
                  value={props.thirdName}
                  name="thirdName"
                  id="thirdName" 
                  label="Отчество" 
                  onChange={props.handleChange}
                  fullWidth />
              </Grid>
              <Grid item xs={12} md={6} >
                <TextField
                  required
                  value={props.dateOfBirth}
                  name="dateOfBirth"
                  id="dateOfBirth"
                  label="Дата рождения"
                  type="date"
                  fullWidth
                  onChange={props.handleChange}
                  InputLabelProps={{
                  shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} >
                <TextField 
                  required
                  value={props.outpatientCardNumber}
                  name="outpatientCardNumber"
                  id="outpatientCardNumber" 
                  label="Номер амбулаторной карты" 
                  onChange={props.handleChange}
                  fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  value={props.clinicalHistoryNumber}
                  name="clinicalHistoryNumber"
                  id="clinicalHidtoryNumber"
                  label="Номер истории болезни"
                  onChange={props.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  value={props.treatingDoctor}
                  name="treatingDoctor"
                  id="treatingDoctor"
                  label="Ф.И.О. Лечащего врача"
                  onChange={props.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                required
                value={props.diagnosis}
                name="diagnosis"
                id="diagnosis"
                label="Диагноз"
                multiline
                rows={4}
                fullWidth
                onChange={props.handleChange}
                variant="outlined"
                />
              </Grid>    
              <Grid item xs={12} md={12}>
                <Button type="submit" variant="contained" color="primary">
                  Рандомизировать
                </Button>
              </Grid>
            </Grid>
            </form>
        </CardContent>
      </Card>
    )
}