import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

import logo from '../../logo.png';

export default function LoginForm(props) {
    return (
        <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box pt={5}>
                        <AppName />
                    </Box>
                    <Box pt={2}  align="center">
                        <img src={logo} alt="Logo" width="200" height="auto"/>
                    </Box>
                    <Box mt={2}>
                        <form onSubmit={props.handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Имя пользователя"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            value={props.username}
                            onChange={props.handleChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Пароль"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={props.password}
                            onChange={props.handleChange}
                        />
                        <Box mt={2}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            > Вход
                            </Button>
                        </Box>
                        </form>
                    </Box>
                    <Box pt={10}>
                        <Info />
                    </Box>
                  </Container>
    )
}


function Info() {
    return (
      <>
        <Typography variant="body2" color="textPrimary" align="center" padding="10">
          <EmailIcon style={{cursor:'pointer', verticalAlign: 'middle'}}/>&nbsp; asu.development@omr.by &nbsp;
          <PhoneIcon style={{cursor:'pointer', verticalAlign: 'middle'}}/>&nbsp;+375-17-389-99-13 &nbsp;
        </Typography>
        <hr />
        <Typography variant="body2" color="textPrimary" align="center">
          Отдел АСУ | &nbsp;
          <Link color="inherit" href="https://omr.by/" target="_blank">
            РНПЦ ОМР им. Н.Н. Александрова
          </Link>{' '} 
        </Typography>
      </>
    );
  }

function AppName() {
  return (
    <>
        <br />
        <Typography variant="h6" color="textPrimary" align="center" padding="10">
        Рандомизация<br/>
        </Typography>
        <hr />
        <br />
      </>
    );
  }