import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ProtocolReportData from './reportTemplates/ProtocolReportData'

const useStyles = makeStyles((theme) => ({
  secondaryHeading: {
    color: '#3f51b5',
  },
}));


export default function RandomizeJournal(props) {
  const classes = useStyles();

  return (
    <>    
    {props.data.protocols.map((protocol) => (<>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}><b>
        {protocol.name}</b></Typography>
        {(protocol.is_completed === "True") ? <Typography className={classes.secondaryHeading}>&nbsp; (научный протокол завершён)</Typography>
        : null }
      </AccordionSummary>
      <AccordionDetails>
        {console.log("protocol: ", protocol)}
        <ProtocolReportData protocolName={protocol.name} protocolData={protocol} />
    </AccordionDetails>
  </Accordion>
  </>))} 
  </>
  );
}
