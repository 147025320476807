import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }

export default function Snackbars(props) {
    return (
        <>
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} 
                open={props.showSuccessfullyRandomizedSnackbar} 
                autoHideDuration={6000} 
                onClose={props.handleCloseSnackbar}>
                <Alert severity="success">
                  Пациент успешно рандомизирован
                </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                      open={props.showErrorsDuringRandomizationSnackbar} 
                      autoHideDuration={6000} 
                      onClose={props.handleCloseSnackbar}>
                <Alert severity="error">
                  Ошибка
                </Alert>
      </Snackbar>
      </>
    )
}