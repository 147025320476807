import React, { useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

import PatientReport from '../reportTemplates/PatientReport'
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'scroll',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: "750px",
      color: "#000000"
  
    },
    table: {
      minWidth: 650,
    },
  }));

export default function PatientReportDialog(props) {
    const classes = useStyles();
    const componentRef = useRef();
 
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
      });

    return (
        <Dialog
        classes={{ paper: classes.paper}}
        open={props.open}
        onClose={props.handleClose}
        scroll={props.scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        >
        <DialogTitle id="scroll-dialog-title">
          <Grid container direction="row" alignItems="center">
            <Grid item align="left" xs={6} md={6}>
              Отчёт по рандомизации
            </Grid>
            <Grid item align="right" xs={6} md={6}>
              <CloseIcon style={{cursor:'pointer', color:'#3f51b5', verticalAlign: 'middle'}} onClick={props.handleClose}/>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers={props.scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <div align="center" style={{color: "black"}} ref={componentRef}>
              <Box width={700}>
              <PatientReport outpatientCardNumber={props.patientData.outpatient_card_number}
                             clinicalHistoryNumber={props.patientData.clinical_history_number}
                             fullName={props.patientData.full_name}
                             dateOfBirth={props.patientData.date_of_birth}
                             dateOfRandomization={props.patientData.date_of_randomization}
                             treatingDoctor={props.patientData.treating_doctor}
                             diagnosis={props.patientData.diagnosis}
                             protocolNumber={props.patientData.protocol_number}
                             targetGroup={props.patientData.target_group}
                             randomizer={props.patientData.randomizer}/>
            </ Box>
            </div>  
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePrint} color="primary">
            Печать &nbsp;<PrintIcon style={{color:'#3f51b5'}}/>
          </Button>
        </DialogActions>
      </Dialog>
    )
}