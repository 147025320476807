import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination'
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import PatientReportDialog from '../reportDialogs/PatientReportDialog'
import ProtocolReportDialog from '../reportDialogs/ProtocolReportDialog'

const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 650,
    },
    secondaryHeading: {
      color: '#3f51b5',
    },
  }));

export default function LocalizationReportData(props) {
    const classes = useStyles();

    const [showPatientReport, setShowPatientReport] = useState(false);
    const [showLocalizationReport, setShowLocalizationReport] = useState(false);
    const [scroll, setScroll] = useState('paper');
    const [patientData, setPatientData] = useState("")

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);


    function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [day, month, year].join('.');
    }
  
    function handleShowPatientReportDialog (param1) {
      setPatientData(param1)
      setShowPatientReport(true);
      setScroll('paper');
    }
  
    const handleClosePatientReport = () => {
      setShowPatientReport(false);
    };
  
    const handleShowLocalizationReport = () => { 
      setShowLocalizationReport(true);
      setScroll('paper');
    };
  
    const handleCloseLocalizationReport = () => {
      setShowLocalizationReport(false);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    return (
    <>
        <PatientReportDialog patientData={patientData}
                             open={showPatientReport}
                             handleClose={handleClosePatientReport}
                             scroll={scroll}/>
        <ProtocolReportDialog isShown={showLocalizationReport} 
                              protocolName={props.protocolName}
                              protocolData={props.protocolData}
                              open={showLocalizationReport}
                              handleClose={handleCloseLocalizationReport}
                              scroll={scroll}/>
        <Grid container spacing={1}>
          <Grid item align="left" xs={12} md={12}>
          {(props.isShown) 
          ? null
          : <Button color="primary" onClick={handleShowLocalizationReport}>
              <LibraryBooksIcon style={{color:'#3f51b5'}}/> &nbsp; <small> отчёт по локализации </small> 
            </Button>
          }
          <hr/>
          <small>
          {(props.isShown) 
          ? <> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Дата составления отчёта: {formatDate(Date())}<hr/></>
          : null
          }
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Рандомизировано пациентов: <b>{props.protocolData.ranomized_patiens_amount}</b> из <b>{props.protocolData.overall_patients_amount}</b> <br/>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{textDecoration: 'underline'}}>
          Количество пациентов в группах:</span><br/>
          {props.protocolData.target_group_statistics.map((target_group_item) => (
            <> 
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{target_group_item.target_group_name}:&nbsp; 
            <b>{target_group_item.target_group_randomized_patients_amount}</b> из&nbsp;
            <b>{target_group_item.target_group_overall_patients_amount}</b><br/>
            </>
          ))}
          </small>   
          <hr/> 
          </Grid>
        <Grid item align="left" xs={12} md={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                    <TableCell align="center">Пациент</TableCell>
                    <TableCell align="center">Дата рождения</TableCell>
                    <TableCell>№ а/к</TableCell>
                    <TableCell align="center">№ и/б</TableCell>
                    <TableCell align="center">Группа</TableCell>
                    <TableCell align="center">Лечащий врач</TableCell>
                    <TableCell align="center">Дата рандомизации</TableCell>
                    {(props.isShown) 
                    ? null
                    : <TableCell align="center">Отчёт</TableCell>
                    } 
                </TableRow>
              </TableHead>
              <TableBody>   
              {(props.isShown) 
                ? props.protocolData.patients.map((patient) => (
                  <TableRow key={patient.id}>
                    <TableCell align="center">{patient.full_name}</TableCell>
                    <TableCell align="center">{patient.date_of_birth}</TableCell>
                    <TableCell component="th" scope="row">{patient.outpatient_card_number}</TableCell>
                    <TableCell align="center">{patient.clinical_history_number}</TableCell>
                    <TableCell align="center">{patient.target_group}</TableCell>
                    <TableCell align="center">{patient.treating_doctor}</TableCell>
                    <TableCell align="center">{patient.date_of_randomization}</TableCell>
                    </TableRow>
                  ))  
                : props.protocolData.patients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((patient) => (
                  <TableRow key={patient.id}>
                    <TableCell align="center">{patient.full_name}</TableCell>
                    <TableCell align="center">{patient.date_of_birth}</TableCell>
                    <TableCell component="th" scope="row">{patient.outpatient_card_number}</TableCell>
                    <TableCell align="center">{patient.clinical_history_number}</TableCell>
                    <TableCell align="center">{patient.target_group}</TableCell>
                    <TableCell align="center">{patient.treating_doctor}</TableCell>
                    <TableCell align="center">{patient.date_of_randomization}</TableCell>
                     <TableCell align="center"><LibraryBooksIcon style={{cursor:'pointer', color:'#3f51b5'}} 
                        onClick={() => {handleShowPatientReportDialog(patient)}}/>
                      </TableCell>
                    </TableRow>
                  )) 
              }
              </TableBody>
            </Table>
          </TableContainer>
          {(props.isShown) 
           ? null
           :  <TablePagination
              labelDisplayedRows={
                ({ from, to, count }) => {
                  return '' + from + '-' + to + ' из ' + count
                }
              }
              labelRowsPerPage="Пациентов на странице"
              backIconButtonText="Предыдущая страница"
              nextIconButtonText="Следующая страница"
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={props.protocolData.patients.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              />
          }
        </Grid>
      </Grid>
      </>
    )
}