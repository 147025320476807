import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: '6vh'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Navbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ background: '#212121' }}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Рандомизация
          </Typography>
          <Typography variant="h6" align="right" className={classes.title}>
              {props.full_username}
             &nbsp;&nbsp;&nbsp;
          </Typography>
          <ExitToAppIcon style={{cursor: 'pointer'}} onClick={props.handleLogout}/>
        </Toolbar>
      </AppBar>
    </div>
  );
}